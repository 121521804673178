
<template>
  <div>
    <!-- <PageHeader /> -->
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <b-row class="mb-3">
            <b-col>
              <h5 class="mt-0 mb-1">
                {{ $t('fields.profile')}}
              </h5>
            </b-col>
          </b-row>
          <b-row class="pt-2">
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <i class="uil-calender"></i>
                {{ $t('user.registered_date')}}
              </small>
              <p class="text-muted">
                {{ playerDetail.createdAt | datetime}}
              </p>
            </b-col>
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <i class="uil-calender"></i>
                {{ $t('fields.lastLogin')}}
              </small>
              <p class="text-muted">
                {{ playerDetail.lastLoggedInAt | datetime }}
              </p>
            </b-col>

          </b-row>
          <b-row class="pt-2">
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <i class="uil uil-user-check"></i>
                {{ $t('user.referral')}}
              </small>
              <p class="text-muted">
                {{ playerDetail.affiliateByUsername || '-' }}
              </p>
            </b-col>
            <b-col
              cols="6"
            >
              <small class="mb-1">
                <i class="uil-shield"></i>
                {{ $t('agent.title')}}
              </small>
              <p class="text-muted">
                {{ playerDetail.agentName || '-' }}
              </p>
            </b-col>
          </b-row>
          <PlayerProfileForm />
        </b-card>
      </b-col>
      <!-- player summary -->
      <b-col
        cols="12"
        md="6"
      >
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('routes.VIEW_SUMMARY') }}
          </h5>
          <b-link
            :to="`/credits/all?search=${playerDetail.profile.phoneNumber}`"
            class="float-right"
          >
            {{ $t('credits.credit_transaction') }}
            <i class="uil uil-arrow-up-right ml-2"></i>
          </b-link>
          <h6>
            <i class="uil uil-usd-circle"></i>
            {{ $t('credits.credit')}}
          </h6>
          <b-row class="mb-4">
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.current')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.credit | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.hold_balance')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.holdCredit | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.bonus')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.totalBonus | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.deposit_amount')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.totalDeposit | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.withdraw_amount')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.totalWithdraw | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('credits.margin')}}
              </small>
              <div class="text-muted">
                {{ (playerSummary.totalDeposit - playerSummary.totalWithdraw) | currency }}
              </div>
            </b-col>
          </b-row>

          <h6>
            <i class="uil uil-coins"></i>
            ยอดเดิมพัน
          </h6>
          <b-row class="mb-4">
            <b-col cols="4">
              <small class="mb-1">
                สล็อต
              </small>
              <div class="text-muted">
                {{ playerSummary.totalSlotBet | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                ยิงปลา
              </small>
              <div class="text-muted">
                {{ playerSummary.totalFishingBet | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                คาสิโน
              </small>
              <div class="text-muted">
                {{ playerSummary.totalCasinoBet | currency }}
              </div>
            </b-col>
          </b-row>

          <h6>
            <i class="uil uil-users-alt"></i>
            {{ $t('affiliate.title')}}
          </h6>

          <b>- ค่าแนะนำจากยอดฝาก</b>
          <b-row class="mb-2">
            <b-col cols="4">
              <small class="mb-1">
                รายได้คงเหลือ
              </small>
              <div class="text-muted">
                {{ playerSummary.affiliatePromotionCredit | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                รายได้ทั้งหมด
              </small>
              <div class="text-muted">
                {{ playerSummary.affiliatePromotionTotalCredit | currency }}
              </div>
            </b-col>
          </b-row>

          <b>- ค่าแนะนำจากยอดแทง</b>
          <b-row class="mb-2">
            <b-col cols="4">
              <small class="mb-1">
                รายได้คงเหลือ
              </small>
              <div class="text-muted">
                {{ playerSummary.affiliateCredit | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                รายได้ทั้งหมด
              </small>
              <div class="text-muted">
                {{ playerSummary.affiliateTotalCredit | currency }}
              </div>
            </b-col>
          </b-row>

          <b>- รวม</b>
          <b-row class="mb-4">
            <b-col cols="4">
              <small class="mb-1">
                รายได้คงเหลือ
              </small>
              <div class="text-muted">
                {{ (playerSummary.affiliateCredit + playerSummary.affiliatePromotionCredit) | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                รายได้ทั้งหมด
              </small>
              <div class="text-muted">
                {{ (playerSummary.affiliateTotalCredit + playerSummary.affiliatePromotionTotalCredit) | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                {{ $t('affiliate.tabs.members')}}
              </small>
              <div class="text-muted">
                {{ playerSummary.affiliateMemberCount | money }}
              </div>
            </b-col>
          </b-row>

          <h6>
            <i class="uil uil-usd-circle"></i>
            Cashback
          </h6>
          <b-row>
            <b-col cols="4">
              <small class="mb-1">
                เงินคืนคงเหลือ
              </small>
              <div class="text-muted">
                {{ playerSummary.cashbackCredit  | currency }}
              </div>
            </b-col>
            <b-col cols="4">
              <small class="mb-1">
                เงินคืนทั้งหมด
              </small>
              <div class="text-muted">
                {{ playerSummary.cashbackTotalCredit  | currency }}
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('routes.VIEW_BANK_ACCOUNTS') }}
          </h5>
          <div>
            <PlayerBankAccountForm />
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h5 class="mt-0 mb-3">
            {{ $t('user.change_password') }}
          </h5>
          <div>
            <PlayerPasswordForm />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card style="height: 600px; overflow-y: auto">
          <h5>ประวัติฝากเงิน</h5>
          <b-table
              :busy="isFetchingPlayer"
              :fields="depositTransactionFields"
              :items="depositTransactions"
              show-empty
          >
            <template #cell(transactionDateTime)="data">
              {{ data.value | datetime }}
            </template>
            <template #cell(amount)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(status)="data">
              <b-badge v-if="data.value === 0" variant="success">
                {{ $t('utils.status.success_credit') }}
              </b-badge>
              <b-badge v-else-if="data.value === 1" variant="success">
                ฝากมือ
              </b-badge>
              <b-badge v-else-if="data.value === 2" variant="warning">
                ไม่เจอยูส
              </b-badge>
              <b-badge v-else-if="data.value === 4" variant="warning">
                ดึงเครดิตคืน
              </b-badge>
              <b-badge v-else-if="data.value === 'processing'" variant="warning">
                กำลังดำเนินการ
              </b-badge>
              <b-badge v-else variant="danger">
                {{ $t('utils.status.unsuccessful') }}
              </b-badge>
            </template>
            <template #cell(imageUrl)="data">
              <b-button :disabled="!data.value" size="sm" variant="primary" @click="showImage(data.value)">
                <i class="uil uil-eye"></i>
              </b-button>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty="">
              <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card style="height: 600px; overflow-y: auto">
          <h5>ประวัติการถอนเงิน</h5>
          <b-table
              :busy="isFetchingPlayer"
              :fields="withdrawTransactionFields"
              :items="withdrawTransactions"
              show-empty
          >
            <template #cell(updatedAt)="data">
              {{ data.value | datetime }}
            </template>
            <template #cell(amount)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(status)="data">
              <b-badge v-if="data.value === 0">
                รออนุมัติ
              </b-badge>
              <b-badge v-else-if="data.value === 1" variant="warning">
                ซ่อน
              </b-badge>
              <b-badge v-else-if="data.value === 2" variant="danger">
                ปฏิเสธ
              </b-badge>
              <b-badge v-else-if="data.value === 3" variant="success">
                สำเร็จ
              </b-badge>
              <b-badge v-else-if="data.value === 4" variant="success">
                ถอนมือ
              </b-badge>
              <b-badge v-else-if="data.value === 5" variant="success">
                สำเร็จ (ออโต้)
              </b-badge>
              <b-badge v-else-if="data.value === 'processing'" variant="success">
                กำลังทำรายการ
              </b-badge>
              <b-badge v-else variant="danger">
                {{ $t('utils.status.unsuccessful') }}
              </b-badge>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty="">
              <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card>
          <h5>รายได้ค่าแนะนำ</h5>
          <b-table
              :busy="isFetchingPlayerAf"
              :fields="afFields"
              :items="afData"
              show-empty
          >
            <template #cell(date)="data">
              {{ data.value | date }}
            </template>
            <template #cell(income)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(betAmount)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(withdraw)="data">
              {{ data.value | currency }}
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty="">
              <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h5>ประวัติการถอน Cashback</h5>
          <b-table
              :busy="isFetchingPlayerCashback"
              :fields="cashbackFields"
              :items="cashbackData"
              show-empty
          >
            <template #cell(date)="data">
              {{ data.value | date }}
            </template>
            <template #cell(income)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(betAmount)="data">
              {{ data.value | currency }}
            </template>
            <template #cell(withdraw)="data">
              {{ data.value | currency }}
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template #empty="">
              <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <h5>
        รายชื่อลูกข่าย
      </h5>
      <PlayerAfMember :player-id="playerId" />
    </b-card>

    <b-card>
      <h5>
        {{ $t('promotion.history')}}
      </h5>
      <PlayerPromotionRedemptionHistory :player-id="playerId" />
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'แก้ไขข้อมูลผู้เล่น',
  },
  components: {
    PlayerProfileForm: () =>
      import('@components/forms/player-profile-form'),
    PlayerBankAccountForm: () =>
      import('@components/forms/player-bank-account-form'),
    PlayerPasswordForm: () =>
      import('@components/forms/player-password-form'),
    PlayerPromotionRedemptionHistory: () =>
      import('@components/promotions/player-redemption-history'),
    PlayerAfMember: () =>
      import('@components/promotions/player-af-member'),
  },
  data() {
    return {
      depositTransactionFields: [
        {
          key: 'transactionDateTime',
          label: 'เวลาฝาก',
        },
        {
          key: 'amount',
          label: 'ยอดฝาก',
          class: 'text-right',
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'imageUrl',
          label: 'สลิป',
          class: 'text-center',
        },
      ],
      withdrawTransactionFields: [
        {
          key: 'updatedAt',
          label: 'เวลาถอน',
        },
        {
          key: 'amount',
          label: 'ยอดถอน',
          class: 'text-right',
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        }
      ],
      afFields: [
        {
          key: 'date',
          label: 'วันที่',
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
          class: 'text-right',
        },
        {
          key: 'income',
          label: 'รายได้',
          class: 'text-right',
        },
        { 
          key: 'withdraw',
          label: 'ยอดถอน',
          class: 'text-right',
        },
      ],
      cashbackFields: [
        {
          key: 'date',
          label: 'วันที่',
        },
        {
          key: 'betAmount',
          label: 'ยอดแทง',
          class: 'text-right',
        },
        {
          key: 'income',
          label: 'รายได้',
          class: 'text-right',
        },
        { 
          key: 'withdraw',
          label: 'ยอดถอน',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingPlayer: (state) => state.player.isFetchingPlayer,
      isFetchingPlayerAf: (state) => state.player.isFetchingPlayerAf,
      isFetchingPlayerCashback: (state) => state.player.isFetchingPlayerCashback,
    }),
    ...mapGetters(['playerDetail', 'playerCashbackLogs', 'playerAfLogs']),
    playerId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
    playerSummary() {
      return this.playerDetail.summary
    },
    afData() {
      return this.playerAfLogs
    },
    cashbackData() {
      return this.playerCashbackLogs
    },
    depositTransactions() {
      return this.playerDetail.depositTransactions
    },
    withdrawTransactions() {
      return this.playerDetail.withdrawTransactions
    }
  },
  created() {
    this.onFetch()
  },
  methods: {
    ...mapActions([
      'fetchPlayer',
      'deletePlayer',
      'fetchPlayerAfLogs',
      'fetchPlayerCashbackLogs',
    ]),
    showImage(imageUrl) {
      const h = this.$createElement
      const messageVNode = h('img', { class: ['w-100'], attrs: { src: imageUrl }})
      this.$bvModal.msgBoxOk([messageVNode], {
        centered: true,
        okTitle: 'ปิด',
      })
    },
    onFetch() {
      if (this.playerId) {
        this.fetchPlayer(this.playerId)
        this.fetchPlayerAfLogs(this.playerId)
        this.fetchPlayerCashbackLogs(this.playerId)
      }
    },
    displayStatusColor() {
      if (this.playerDetail.is_verified) {
        return 'success'
      } else if (this.playerDetail.is_banned || this.playerDetail.is_deleted) {
        return 'danger'
      }
      return 'light'
    },
    onDeletePlayer() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePlayer(this.playerId)
          }
        })
    },
    async cancelPromotion() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `${this.$t('messages.confirm')}`,
        {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        }
      )
      if (confirm) {
        // do
      }
      this.fetchPlayer(this.playerId)
    },
    async onClearPromotionReset() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        'การเคลียร์โปรโมชั่นจากการกระทำนี้ เป็นการเคลียร์โดยข้ามเงื่อนไขระบบบางอย่าง กรุณาตรวจสอบข้อมูลให้รอบคอบก่อนใช้งาน',
        {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        }
      )
      if (confirm) {
        // do
      }
    },
  },
}
</script>
